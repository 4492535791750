import { Content } from "antd/es/layout/layout";
import { CreateProfileWithSteps } from "consultProfileCreation/components/CreateProfileWithSteps";
import { DownloadAppLinks } from "consultProfileCreation/components/DownloadAppLinks";
import { FeatureInfo } from "consultProfileCreation/components/FeatureInfo";

export const AppFeatures = () => {
  return (
    <>
      <FeatureInfo isFlip={false} />
      <Content style={{ background: "#f0f0f5" }}>
        <FeatureInfo isFlip={true} />
      </Content>
      <FeatureInfo isFlip={false} />
      <Content style={{ background: "#f0f0f5" }}>
        <CreateProfileWithSteps />
      </Content>
      <DownloadAppLinks />
    </>
  );
};
