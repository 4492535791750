export const isMobileNumberValid = (mobile: number) => {
  let isMobileNumberValid = true;

  if (String(mobile).length !== 10) {
    isMobileNumberValid = false;
  }
  if (isNaN(mobile)) {
    isMobileNumberValid = false;
  }

  return isMobileNumberValid;
};

export const setUserToken = (token: string) => {
  localStorage.setItem("token", token);
};

export const getUserToken = () => localStorage.getItem("token");

export const clearUserToken = () => localStorage.setItem("token", "");
