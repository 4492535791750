import "./AppHeader.less";
import { Layout, Menu } from "antd";
import NavBar from "./NavBar/NavBar";
import SideBar from "component/AppHeader/SideBar/SideBar";
import {
  HomeOutlined,
  InfoCircleOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { AppFooter } from "component/AppFooter/AppFooter";
import { $HeightAdjustmentSpacing } from "styles/global";

const menuMap = ["/home", "/meeting", "/about"];
const TopicMenu = ({ topics, changeSelectedKey }: any) => {
  const { pathname } = useLocation();
  const menuIndex = menuMap.indexOf(pathname);
  const styledTopics: any = [];

  topics.forEach((topic: any, index: any) =>
    styledTopics.push(
      <Menu.Item
        icon={topic.icon}
        key={index}
        title={topic.path}
        onClick={changeSelectedKey}
      >
        {topic.label}
      </Menu.Item>
    )
  );

  return (
    <Menu mode="inline" selectedKeys={[menuIndex + ""]}>
      {styledTopics}
    </Menu>
  );
};

export const AppHeader = (props: any) => {
  const navigate = useNavigate();

  const topics = [
    { key: "home", path: "home", label: "Home", icon: <HomeOutlined /> },
    {
      key: "meeting",
      path: "meeting",
      label: "Meetings",
      icon: <SettingOutlined />,
    },
    {
      key: "about",
      path: "about",
      label: "About",
      icon: <InfoCircleOutlined />,
    },
  ];

  const changeSelectedKey = (event: any, f: any) => {
    navigate("/" + event.item.props.title);
  };
  const Menu = (
    <TopicMenu topics={topics} changeSelectedKey={changeSelectedKey} />
  );
  return (
    <div>
      <NavBar menu={Menu} />
      <Layout
        className="main-routes-container"
        style={{ marginTop: $HeightAdjustmentSpacing + "px" }}
      >
        <SideBar menu={Menu} />
        <Layout.Content className="children-router">
          {props.children}
        </Layout.Content>
      </Layout>
      <AppFooter />
    </div>
  );
};
