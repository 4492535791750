import { useState } from "react";
import { Drawer, Button } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import "../AppHeader.less";
import logo from "assets/logo/logo.png";
import { $HEADER_HEIGHT } from "styles/global";
import ProfileDropdown from "../ProfileDropDown/ProfileDropDown";

const NavBar = ({ menu }: any) => {
  const [visible, setVisible] = useState(false);
  return (
    <div className="navbar" style={{ height: $HEADER_HEIGHT + "px" }}>
      <div className="left-part">
        <Button
          className="menu"
          type="primary"
          icon={<MenuOutlined />}
          onClick={() => setVisible(true)}
        />
        <Drawer
          title="ConsultPro"
          placement="left"
          className="my-navbar"
          onClick={() => setVisible(false)}
          onClose={() => setVisible(false)}
          open={visible}
        >
          {menu}
        </Drawer>
        <a href="/">
          <img className="logo" src={logo} alt="logo" />
        </a>
      </div>
      <div className="center-part">
        <span className=" logo-name">Xpert Caller</span>
      </div>
      <div className="right-part">
        <ProfileDropdown />
      </div>
    </div>
  );
};

export default NavBar;
