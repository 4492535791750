import { getUserToken } from "common/util";
import { useQuery } from "react-query";
import { MEETING_SERVICE } from "expert-common/dist";

const { getMeetings } = MEETING_SERVICE;

export const useMeetings = (payload: any) => {
  const token = getUserToken();
  return useQuery(
    ["use meetings", payload, token],
    () => getMeetings(payload),
    {
      enabled: !!token,
    }
  );
};
