import { useMeetings } from "hooks/useMeetings";

interface IMeetingsList {
  date: number;
  status: number[];
}
export const MeetingsList = (props: IMeetingsList) => {
  const payload = {
    date: props.date,
    status: props.status,
  };
  const { data } = useMeetings(payload);
  console.log(data);

  return (
    <>
      
      {data?.length > 0 ? "" : "You have no meeetings"}
      <ul>
        {data?.map((_r: any, i: number) => (
          <div>{i}</div>
        ))}
      </ul>
    </>
  );
};
