import { Button, Col, Row } from "antd";
import "./CreateProfileWithSteps.less";
import { useResponsive } from "../../styles/hook/useResponsive";

import { UsergroupDeleteOutlined } from "@ant-design/icons";

export const CreateProfileWithSteps = () => {
  const responsiveCss = useResponsive();
  const responsiveGrid = {
    xs: 24,
    sm: 24,
    md: 8,
    lg: 8,
    xl: 8,
    xxl: 8,
  };
  const FeatureCard = () => {
    return (
      <Col {...responsiveGrid}>
        <p>
          <UsergroupDeleteOutlined className="create-profile-icon" />
        </p>
        <span className="create-profile-title app-section-subtitle-responsive">
          Register or find yourself on Practo.com
        </span>
        <p className="create-profile-subtitle app-section-subtitle-responsive">
          Enter your name, email id, mobile number and clinic or establishment
          name.
        </p>
      </Col>
    );
  };

  return (
    <Row
      className="create-profile-with-steps pro-row"
      style={{ textAlign: responsiveCss.md ? "inherit" : "center" }}
    >
      <Col span={24}>
        <p className="title app-section-title-responsive">
          Edit your profile from anywhere, effortlessly
        </p>
      </Col>
      <FeatureCard />
      <FeatureCard />
      <FeatureCard />
      <div
        style={{ textAlign: "center", width: "100%", paddingBottom: "2rem" }}
      >
        <Button
          size="large"
          style={{
            background: "#ffa000",
            width: !responsiveCss.md ? "80%" : "",
          }}
        >
          Create your profile
        </Button>
      </div>
    </Row>
  );
};
