import { clearUserToken, getUserToken } from "common/util";
import { useEffect, useState } from "react";
import { USER_SERVICE, AUTH } from "expert-common/dist";

export const useValidateLoginUser = () => {
  const [isChecking, setIsChecking] = useState(true);
  const checkUser = async () => {
    setIsChecking(true);
    const response = await USER_SERVICE.getUser();
    if (!response || !response.userId) {
      clearUserToken();
    } else {
      console.log("User verified and it is valid");
    }
    setIsChecking(false);
  };
  console.log("checking logged in user :SMILE");
  useEffect(() => {
    AUTH.setToken(getUserToken() || "");
    console.log("token", AUTH.getToken());
    getUserToken() && checkUser();
  }, []);

  return isChecking;
};
