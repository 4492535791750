import { Dropdown, Avatar, MenuProps } from "antd";
import { UserOutlined, LogoutOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { clearUserToken } from "common/util";

const ProfileDropdown = () => {
  const navigate = useNavigate();
  // Define the handler function for the menu click event
  const handleMenuClick = (e: any) => {
    // Perform different actions based on the menu item key
    switch (e.key) {
      case "profile":
        // Navigate to the profile page
        break;
      case "settings":
        // Navigate to the settings page
        break;
      case "logout":
        // Logout the user
        clearUserToken();
        navigate("/home");
        break;
      case "login":
        // Login the user
        navigate("/consult");
        break;
      default:
        // Do nothing
        break;
    }
  };

  const items: MenuProps["items"] = [
    {
      key: "login",
      label: "Login",
      icon: <UserOutlined />,
      onClick: () => handleMenuClick({ key: "login" }),
    },
    {
      key: "logout",
      label: "Logout",
      icon: <LogoutOutlined />,
      danger: true,
      onClick: () => handleMenuClick({ key: "logout" }),
    },
    // {
    //   key: "1",
    //   label: "Profile",
    //   icon: <UserOutlined />,
    // },
    // {
    //   key: "2",
    //   label: "Setting",
    //   icon: <SettingOutlined />,
    // },
  ];
  return (
    // Wrap the avatar component in the dropdown component
    <Dropdown
      menu={{ items }}
      trigger={["click"]}
      placement="bottomRight"
      onOpenChange={handleMenuClick}
    >
      <div>
        <Avatar icon={<UserOutlined />} />
      </div>
    </Dropdown>
  );
};

export default ProfileDropdown;
