import LottieLoader from "react-lottie-loader";
import catAnimationData from "./animation.json";
import "./loader.less";
export const Loader = () => {
  return (
    <div className="loader-container">
      <LottieLoader animationData={catAnimationData} />
    </div>
  );
};
