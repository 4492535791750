import { Col, Row } from "antd";

import "./FeatureInfo.less";
import { useResponsive } from "../../styles/hook/useResponsive";
export const FeatureInfo = (props: any) => {
  const { isFlip } = props;
  const responsiveGrid = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 12,
    xxl: 12,
  };
  const responseFont = useResponsive();

  return (
    <>
      <Row
        className="feature-info-container pro-row"
        style={{
          flexDirection: isFlip ? "row-reverse" : "row",
          background: isFlip ? "#f0f0f5" : "inherit",
        }}
      >
        <Col
          {...responsiveGrid}
          className="image-container"
          style={{ display: responseFont.md ? "block" : "none" }}
        >
          <img
            className="image"
            alt="Profile Editor"
            title="Profile Editor"
            src="https://www.practo.com/providers/static/images/pages/home/profiles.png"
          />
        </Col>
        <Col {...responsiveGrid}>
          <p className="title app-section-title-responsive">
            Edit your profile from anywhere, effortlessly
          </p>
          <ul>
            <li className="subtitle app-section-subtitle-responsive">
              Easily add or modify your details
            </li>
            <li className="subtitle app-section-subtitle-responsive">
              Add information that matters to your patients - timings, fees, new
              services, and much more
            </li>
            <li className="subtitle app-section-subtitle-responsive">
              Keep all your information up to date, with ease
            </li>
          </ul>
        </Col>
      </Row>
    </>
  );
};
