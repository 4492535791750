export const BASE_URL = "https://52.66.178.23:8082";

export const MEETINGS_STATUS = {
  DEFAULT: 0, // Pending for payment
  BOOKED: 1, // Payment done
  STARTED: 2,
  COMPLETED: 3,
  CANCELLED: 4,
  NOTATTENED: 5,
};
