
import "./AppFooter.less";
import "styles/colors.less";
import logo from "assets/logo/logo.png";

export const AppFooter = () => {
  return (
    <div className="app-footer-container">
      <a href="/">
        <img className="logo" src={logo} alt="logo" />
      </a>
    </div>
  );
};
