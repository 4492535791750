import { Content } from "antd/es/layout/layout";
import "./DownloadAppLinks.less";
import { Col, Row } from "antd";
import { useResponsive } from "../../styles/hook/useResponsive";

export const DownloadAppLinks = () => {
  const responsiveGrid = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 12,
    xxl: 12,
  };
  const responsiveCss = useResponsive();

  return (
    <Content style={{ padding: responsiveCss.isLarge ? "50px" : "20px 20px" }}>
      <Row className="downlaod-app-link-container  pro-row">
        <Col
          {...responsiveGrid}
          className="image-container"
          style={{ display: responsiveCss.isLarge ? "block" : "none" }}
        >
          <img
            className="mobile-apps"
            alt="Practo Pro app"
            src="https://www.practo.com/providers/static/images/pages/clinics/partner-app-india-new.png"
          />
        </Col>
        <Col {...responsiveGrid}>
          <p className="title app-section-title-responsive">
            Download Practo Pro app
          </p>
          <h2
            className="subtitle"
            style={{ fontSize: responsiveCss.md ? "25px" : "16px" }}
          >
            A powerful app that lets you manage and grow your practice.
          </h2>

          <ul className="description">
            <li> Manage your profile with advanced profile editor</li>
            <li> Respond to patient stories for your practice</li>
            <li> Provide online consultation to patients</li>
            <li> Manage your clinic with a Ray by Practo subscription</li>
            <li> See patient records from anywhere </li>
            <li> Track your clinic’s performance on the go</li>
            <li> Manage and track your Prime subscription</li>
          </ul>
        </Col>
      </Row>
    </Content>
  );
};
