import { Col, Row } from "antd";
import { Content } from "antd/es/layout/layout";
import "./styles.less";

interface ITitleSubtitle {
  title: string;
  subtitle: string;
}
export const TitleSubtitle = (props: ITitleSubtitle) => {
  return (
    <Content className="title-subtitle--common-container">
      <Row>
        <Col className="title">{props.title}</Col>
      </Row>
      <Row>
        <Col>{props.subtitle}</Col>
      </Row>
    </Content>
  );
};
