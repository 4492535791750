import { Grid } from "antd";

const { useBreakpoint } = Grid;

export const useResponsive = () => {
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  const r = {
    title: "normal",
    subtitle: "normal",
    xs,
    sm,
    md,
    lg,
    xl,
    xxl,
    isLarge: lg || xl || xxl,
    fontSize: { title: "", subtitle: "" },
  };
  // Title
  if (xxl || xl || lg || md) {
    r.title = "40px";
    r.fontSize.title = "40px";
  } else {
    r.title = "24px";
    r.fontSize.title = "24px";
  }

  if (xxl || xl || lg) {
    r.subtitle = "16px";
    r.fontSize.subtitle = "16px";
  } else {
    r.subtitle = "14px";
    r.fontSize.subtitle = "14px";
  }
  return r;
};

// xs: `(max-width: 576px)`,
// sm: `(min-width: 576px)`,
// md: `(min-width: 768px)`,
// lg: `(min-width: 992px)`,
// xl: `(min-width: 1200px)`,
// xxl: `(min-width: 1400px)`
