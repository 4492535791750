import { Button, Tabs, TabsProps } from "antd";
import { getUserToken } from "common/util";
import { useNavigate } from "react-router-dom";
import { MeetingsList } from "./meetingsList";
import moment from "moment-timezone";
import { MEETINGS_STATUS } from "common/config";

export const Meetings = () => {
  const isLogin = getUserToken();
  const navigate = useNavigate();

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Today",
      children: (
        <MeetingsList
          date={moment().startOf("day").valueOf()}
          status={[MEETINGS_STATUS.BOOKED, MEETINGS_STATUS.DEFAULT]}
        />
      ),
    },
    {
      key: "2",
      label: "Tomorrow",
      children: (
        <MeetingsList
          date={moment().endOf("day").valueOf() + 1}
          status={[MEETINGS_STATUS.BOOKED]}
        />
      ),
    },
  ];

  const onChange = (key: string) => {
    console.log(key);
  };
  return (
    <div>
      {isLogin ? (
        <>
          <h2>Your meetings</h2>
          <Tabs items={items} onChange={onChange} />
        </>
      ) : (
        <Button
          type="link"
          onClick={() => {
            navigate("/consult");
          }}
        >
          Please login to see meetings
        </Button>
      )}
    </div>
  );
};
