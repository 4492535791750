import "./home.less";
import useSize from "styles/hook/useSize";
import { $HeightAdjustmentSpacing } from "styles/global";
import { TitleSubtitle } from "component/Title/TitleSubtitle";

const Home = () => {
  const windowSize = useSize();

  return (
    <div
      style={{ height: windowSize[0] - $HeightAdjustmentSpacing }}
      className="home-container"
    >
      {/* <Button
        onClick={() => {
          navigate("/consult");
        }}
      >
        Login
      </Button> */}

      <section>
        <TitleSubtitle
          title="Consult top experts online for any concern"
          subtitle="Private online consultations with verified experts in all specialists"
        />
        <h2>advertice</h2>
        <h3>images</h3>
        <h3>other xpert feature ,info</h3>
      </section>
    </div>
  );
};

export default Home;
